import { TypographyProps } from 'components/atoms/Typography';

export const titleVariant: TypographyProps = {
  size: { base: 24, md: 36, lg: 48 },
  color: 'gray-900',
  letterSpacing: '-0.02em',
  asEl: 'h2',
  fontWeight: 500,
};

export const descriptionVariant: TypographyProps = {
  size: { base: 16, md: 20, lg: 24 },
  color: 'opacity-black',
  fontWeight: 300,
};

export const homeTextVariant: TypographyProps = {
  size: { base: 36, lg: 48, xl: 72 },
  lineHeight: 1.2,
  fontWeight: 300,
  color: 'gray-800',
  letterSpacing: '-0.02em',
};
