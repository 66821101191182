import styled, { css } from 'styled-components';
import { breakpoint } from 'styles';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';

import { FeatureProps } from './models.d';

export const StyledButton = styled(Button)`
  transition: background-color var(--transition);
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  img {
    ${breakpoint.lg} {
      object-position: top;
    }
    ${breakpoint.xl} {
      object-position: center;
    }
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  &[data-glass-variant='light-feature'] {
    pointer-events: none;
  }
`;

export const StyledTitleTypography = styled(Typography)`
  transition: color var(--transition);
`;

export const StyledIcon = styled(Icon)`
  --icon-size: 32px;

  flex-shrink: 0;
  width: var(--icon-size, 48px);
  height: var(--icon-size, 48px);

  path {
    transition: fill var(--transition), stroke var(--transition);
  }

  ${breakpoint.md} {
    --icon-size: 48px;
  }
`;

const featureHover = css`
  cursor: pointer;

  ${StyledTitleTypography} {
    color: var(--c-white);
  }

  ${StyledIcon} {
    path {
      fill: var(--c-white);
    }
  }

  ${StyledButton} {
    pointer-events: none;
    background-color: var(--c-white);

    &::after {
      opacity: 0;
    }

    svg {
      background: none;
    }
  }

  ${StyledImage} {
    opacity: 1;

    @supports not (mix-blend-mode: luminosity) {
      opacity: 0.5;
    }
  }

  &::before {
    opacity: 1;
  }
`;

export const StyledFeature = styled.div<Pick<FeatureProps, 'order'> & { onClick: () => void }>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ order }) => `f${order}`};
  gap: var(--container-gap);
  width: 100%;
  height: 100%;
  min-height: var(--card-height);
  padding: var(--padding-top, 0) var(--padding-right, 0) var(--padding-bottom, 0)
    var(--padding-left, 0);

  ${StyledContentWrapper} {
    gap: var(--content-gap, 8px);
  }

  &[data-variant='icon-glass-feature'] {
    --card-height: 204px;
    --content-gap: var(--gap-xxxxs);
    --padding-top: var(--gap-xxs);
    --padding-right: var(--gap-xxs);
    --padding-bottom: var(--gap-xxs);
    --padding-left: var(--gap-xxs);

    z-index: var(--z-index-ground);

    ${breakpoint.md} {
      --content-gap: var(--gap-xxs);
      --padding-top: var(--gap-md);
      --padding-bottom: var(--gap-md);
    }

    ${breakpoint.lg} {
      --padding-right: var(--gap-md);
      --padding-left: var(--gap-md);
    }
  }

  &[data-variant='contact-us'] {
    --content-gap: var(--gap-xxxxs);
    --padding-top: var(--gap-xxs);
    --padding-right: var(--gap-xxs);
    --padding-bottom: var(--gap-xxs);
    --padding-left: var(--gap-xxs);

    z-index: var(--z-index-ground);
    align-items: center;
    justify-content: center;

    ${StyledContentWrapper} {
      align-items: center;
      justify-content: center;
    }

    ${breakpoint.md} {
      --card-height: 282px;
      --content-gap: var(--gap-xxs);
      --padding-top: var(--gap-md);
    }

    ${breakpoint.lg} {
      --padding-right: var(--gap-md);
      --padding-bottom: var(--gap-md);
      --padding-left: var(--gap-md);
    }

    ${breakpoint.xl} {
      --card-height: 296px;
      --content-gap: var(--gap-md);
    }
  }

  &[data-variant='icon-feature-with-img-hover'] {
    --card-height: 200px;
    --content-gap: var(--gap-xxxxs);
    --padding-top: var(--gap-xxs);
    --padding-right: var(--gap-xxs);
    --padding-bottom: var(--gap-xxs);
    --padding-left: var(--gap-xxs);

    position: relative;
    z-index: var(--z-index-ground);
    justify-content: end;
    max-width: 707px;
    margin: 0 auto;

    ${StyledImage} {
      position: absolute;
      top: 0;
      right: 0;
      z-index: var(--z-index-underground);
      width: 50%;
      height: 100%;
      pointer-events: none;
      mix-blend-mode: luminosity;
      opacity: 0;
      transition: opacity var(--transition);
    }

    ${StyledContentWrapper} {
      justify-content: flex-end;
    }

    &::before {
      position: absolute;
      inset: 0;
      z-index: var(--z-index-underground);
      content: '';
      border-radius: var(--border-radius);
      opacity: 0;
      transition: opacity var(--transition);
    }

    ${StyledButton} {
      --gradient: var(--c-gray-900);
    }

    @media (hover: hover) {
      &:hover {
        ${featureHover}
      }
    }

    @media (hover: none) {
      &[data-hover='true'][data-page='home'],
      &[data-hover='true'][data-page='services'] {
        ${featureHover}
      }
    }

    &[data-element='0'] {
      &::before {
        background: linear-gradient(116.39deg, #00c1fe 0%, #4200ff 100%);
      }

      ${StyledImage} {
        top: -47px;
        right: -30px;
      }
    }

    &[data-element='1'] {
      &::before {
        background: linear-gradient(116.39deg, #3627ff 0.01%, #9a07ff 100%);
      }

      ${StyledImage} {
        top: -46px;
      }
    }

    &[data-element='2'] {
      &::before {
        background: linear-gradient(116.39deg, #9e00ff 0%, #00ffe4 100%);
      }

      ${StyledImage} {
        top: -43px;
      }
    }

    &[data-element='3'] {
      &::before {
        background: linear-gradient(116.39deg, #632dff 46.35%, #c03aff 100%);
      }

      ${StyledImage} {
        top: -43px;
        right: -6px;
      }
    }

    &[data-element='4'] {
      overflow: hidden;

      &::before {
        background: linear-gradient(116.39deg, #6c39ff 0%, #04f9e5 98.96%);
      }

      ${StyledImage} {
        height: auto;
      }
    }

    ${breakpoint.md} {
      --card-height: 232px;
      --content-gap: var(--gap-xxs);
      --padding-top: var(--gap-md);
      --padding-bottom: var(--gap-md);
    }

    ${breakpoint.lg} {
      --card-height: 296px;
      --padding-right: var(--gap-md);
      --padding-left: var(--gap-md);

      justify-content: flex-end;
    }

    &[data-page='home'] {
      --padding-right: var(--gap-sm);
      --padding-left: var(--gap-sm);

      ${breakpoint.md} {
        --padding-top: var(--gap-sm);
        --padding-right: var(--gap-sm);
        --padding-bottom: var(--gap-sm);
        --padding-left: var(--gap-sm);
      }

      ${breakpoint.lg} {
        --card-height: 320px;
        --padding-top: var(--gap-md);
        --padding-bottom: var(--gap-md);
      }

      ${breakpoint.xl} {
        --card-height: 380px;
        --padding-top: var(--gap-xxxl);
        --padding-right: var(--gap-xxxl);
        --padding-bottom: var(--gap-xxxl);
        --padding-left: var(--gap-xxxl);

        &[data-element='0'],
        &[data-element='1'],
        &[data-element='2'],
        &[data-element='3'] {
          ${StyledImage} {
            top: 0;
          }
        }
      }
    }

    &[data-page='services'] {
      ${breakpoint.md} {
        &[data-element='0'],
        &[data-element='1'],
        &[data-element='2'],
        &[data-element='3'] {
          ${StyledImage} {
            top: 0;
          }
        }
      }
    }
  }

  &[data-variant='img-icon-feature'] {
    --card-height: 148px;
    --content-gap: var(--gap-xxxxs);
    --container-gap: var(--gap-xxs);

    ${breakpoint.md} {
      --card-height: 312px;
      --content-gap: var(--gap-xxs);

      align-items: center;

      &:nth-of-type(even) {
        flex-direction: row;
      }

      &:nth-of-type(odd) {
        flex-direction: row-reverse;
      }

      ${breakpoint.xl} {
        padding: 0;
      }
    }

    ${breakpoint.lg} {
      --container-gap: var(--gap-md);
    }

    ${breakpoint.xl} {
      --card-height: 368px;
      --container-gap: var(--gap-xxxl);
    }

    ${StyledContentWrapper} {
      justify-content: center;
      padding: 0 var(--padding-base);

      ${breakpoint.md} {
        max-width: 450px;
      }

      ${breakpoint.lg} {
        padding: 0 var(--gap-md);
      }

      ${breakpoint.xl} {
        padding: 0;
      }
    }

    ${StyledImage} {
      max-height: 300px;

      ${breakpoint.md} {
        flex-shrink: 0;
        width: 48%;
        max-width: 515px;
        max-height: var(--card-height);
      }

      ${breakpoint.xl} {
        max-height: var(--card-height);
      }
    }
  }
`;
