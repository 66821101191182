import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import GlassTile from 'components/atoms/GlassTile';
import Typography from 'components/atoms/Typography';

import { getPageLink } from 'utils/getPageLink';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useScreenService } from 'hooks/useScreenService';

import { typography } from './Feature.constants';
import {
  StyledButton,
  StyledContentWrapper,
  StyledFeature,
  StyledIcon,
  StyledImage,
  StyledTitleTypography,
} from './Feature.styles';

import { FeatureProps } from './models.d';

const Feature = ({
  variant = 'icon-feature',
  whichPage,
  icon,
  title,
  description,
  button,
  className,
  order,
  image,
  sectionVariant,
  ...delegated
}: FeatureProps) => {
  const { isMdUp, isXlUp } = useScreenService();
  const [visible, setVisible] = useState(false);
  const featureRef = useRef<HTMLElement>(null!);

  const isImageFeature = variant === 'icon-feature-with-img-hover';

  const isHomePage = whichPage === 'home';
  const isServicesPage = whichPage === 'services';
  const isCareersPage = whichPage === 'careers';
  const link = getPageLink(button?.link);
  const isLink = !!link;

  const entry = useIntersectionObserver(
    featureRef,
    { freezeOnceVisible: false },
    (isImageFeature && isServicesPage) || (isImageFeature && isHomePage)
  );
  const hasGlassBackground = variant === 'icon-glass-feature' || isImageFeature;

  const { titleVariant, descriptionVariant } = typography(variant);
  const isBenefitsVariant = sectionVariant === 'benefits';

  const featureGap = isMdUp ? 40 : 16;
  const isHPAnimatedItem = isHomePage ? isImageFeature && entry?.isIntersecting && !isXlUp : null;
  const isSPAnimatedItem = isServicesPage
    ? isImageFeature && entry?.isIntersecting && !isMdUp
    : null;

  const isAnimatedFeatures = isHPAnimatedItem || isSPAnimatedItem;

  const isTurnOffGlassTitleBlur =
    (isHomePage && hasGlassBackground) || (isServicesPage && hasGlassBackground);

  const onScroll = () => {
    if (featureRef.current && isAnimatedFeatures) {
      const rect = featureRef?.current.getBoundingClientRect();

      setVisible(
        rect.top <= window.innerHeight / 2 - rect.height / 4 + featureGap &&
          rect.top >= window.innerHeight / 2 - rect.height * 1.25
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isXlUp, entry]);

  const handleNavigate = () => (isLink ? navigate(link) : null);

  return (
    <StyledFeature
      as={hasGlassBackground ? GlassTile : undefined}
      data-testid="feature"
      data-variant={variant}
      data-glass-variant={isTurnOffGlassTitleBlur ? 'light-feature' : 'light'}
      data-element={order}
      data-target={isTurnOffGlassTitleBlur ? 'hover' : ''}
      data-hover={visible}
      data-page={whichPage}
      ref={featureRef}
      {...{ ...delegated, className, order, blurred: hasGlassBackground }}
      onClick={handleNavigate}
    >
      {image?.image ? (
        <StyledImage
          {...{
            image: image.image,
            alt: image.alt,
            objectFit: isImageFeature ? 'contain' : 'cover',
            src: '',
          }}
        />
      ) : null}
      <StyledContentWrapper
        data-glass-variant={isTurnOffGlassTitleBlur ? 'light-feature' : 'light'}
      >
        {icon ? <StyledIcon {...icon} /> : null}
        <StyledTitleTypography
          asEl="h3"
          {...titleVariant}
          size={isHomePage ? { base: 24, md: 30, lg: 36, xl: 48 } : titleVariant?.size}
          fontWeight={isCareersPage ? 500 : titleVariant?.fontWeight}
        >
          {title}
        </StyledTitleTypography>
        {description ? (
          <Typography
            {...descriptionVariant}
            {...(isBenefitsVariant && { color: 'gray-800' })}
            fontWeight={300}
          >
            {renderRichText(description)}
          </Typography>
        ) : null}
        {button ? (
          <StyledButton
            {...button}
            typography={{ padding: { top: 12, bottom: 12, left: 20, right: 20 }, size: 16 }}
            data-target="hover"
          />
        ) : null}
      </StyledContentWrapper>
    </StyledFeature>
  );
};

export default Feature;
