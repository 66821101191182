import { FeatureProps, FeatureTypographyMappedType } from './models.d';

export const typography = (variant: NonNullable<FeatureProps['variant']>) => {
  const titleVariant: FeatureTypographyMappedType = {
    'icon-feature': {
      color: 'gray-800',
      size: { base: 20, lg: 24 },
      fontWeight: 500,
    },
    'icon-feature-dark': {
      color: 'gray-25',
      size: { base: 18, lg: 24 },
      fontWeight: 500,
    },
    'contact-us': {
      size: { base: 24, md: 30 },
      color: 'gray-900',
      align: 'center',
    },
    'icon-feature-with-img-hover': {
      color: 'gray-800',
      size: { base: 24, xl: 30 },
      lineHeight: 1.6,
      fontWeight: 500,
    },
    'img-icon-feature': {
      color: 'gray-800',
      size: { base: 20, lg: 24 },
    },
    'icon-glass-feature': {
      color: 'gray-800',
      fontWeight: 500,
      size: { base: 20, lg: 24 },
    },
  };

  const descriptionVariant: FeatureTypographyMappedType = {
    'icon-feature': {
      color: 'gray-700',
      size: 16,
    },
    'icon-feature-dark': {
      color: 'gray-100',
      size: 16,
    },
    'img-icon-feature': {
      color: 'gray-700',
      size: 16,
    },
  };

  return { titleVariant: titleVariant[variant], descriptionVariant: descriptionVariant[variant] };
};
