import styled from 'styled-components';
import { breakpoint } from 'styles';

import { StyledGlassTile } from 'components/atoms/GlassTile/GlassTile.styles';
import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import { StyledImage } from 'components/molecules/Feature/Feature.styles';

export const StyledCircleImage = styled(Image)`
  display: none;
  width: 624px;
  aspect-ratio: 1;
`;

export const StyledHomeTextVariant = styled(Typography)`
  ${breakpoint.md} {
    max-width: 210px;
    transform: translateY(-80%);
  }

  ${breakpoint.lg} {
    max-width: 350px;
    transform: translateY(0);
  }

  ${breakpoint.xl} {
    max-width: 420px;
  }

  ${breakpoint.xxl} {
    max-width: 600px;
  }

  b {
    font-weight: 500;
  }
`;

export const StyledIntro = styled.div`
  display: grid;
  grid-area: intro;
  gap: var(--gap-xxxxxs);
  justify-items: space-around;
  height: fit-content;
  margin-bottom: calc(var(--gap-md) - var(--gap-xxs));

  ${breakpoint.md} {
    gap: var(--gap-xxxxs);
    margin-bottom: 0;
  }
`;

export const StyledList = styled.div`
  display: grid;
  gap: var(--gap-xxs);
  align-items: center;

  ${breakpoint.md} {
    gap: var(--gap-md) var(--gap-xxs);
  }

  ${breakpoint.lg} {
    gap: var(--gap-md);
  }
`;

export const StyledWrapper = styled.section`
  position: relative;

  /* UX UI */
  &[data-page='ux-ui'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3'
        'f4'
        'f5'
        'f6';

      ${breakpoint.md} {
        grid-template: repeat(4, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f4'
          'f5 f6';
      }

      ${breakpoint.lg} {
        grid-template: repeat(3, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          'f2 f3 f4'
          'f5 f6 f6';
      }
    }
  }

  /* Content migration */
  &[data-page='content-migration'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3';

      ${breakpoint.md} {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f3';
      }

      ${breakpoint.lg} {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          '. f2 f3';
      }
    }

    ${StyledCircleImage} {
      ${breakpoint.md} {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        transform: translate(-50%, 55%);
      }

      ${breakpoint.lg} {
        width: 886px;
        transform: translate(-40%, 80%);
      }

      ${breakpoint.xl} {
        transform: translate(-50%, 80%);
      }
    }
  }

  /* Outsourcing */
  &[data-page='outsourcing'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3';

      ${breakpoint.md} {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f3';
      }

      ${breakpoint.lg} {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          '. f2 f3';
      }
    }

    ${StyledCircleImage} {
      ${breakpoint.md} {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        transform: translate(-50%, 55%);
      }

      ${breakpoint.lg} {
        width: 886px;
        transform: translate(-40%, 80%);
      }

      ${breakpoint.xl} {
        transform: translate(-50%, 80%);
      }
    }
  }

  /* Salesforce */
  &[data-page='salesforce'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3'
        'f4';

      ${breakpoint.md} {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f4';
      }

      ${breakpoint.lg} {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          'f2 f3 f4';
      }
    }
  }

  /* React */
  &[data-page='react'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3'
        'f4';

      ${breakpoint.md} {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f4';
      }

      ${breakpoint.lg} {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          'f2 f3 f4';
      }
    }
  }

  /* Services listing page */
  &[data-page='services'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3'
        'f4';
      gap: var(--gap-sm);

      ${breakpoint.md} {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        grid-template-areas:
          'intro f0'
          'f1 f2'
          'f3 f4';
        gap: var(--gap-md) var(--gap-xxs);
        margin-top: var(--gap-xs);
      }

      ${breakpoint.lg} {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-template-areas:
          'intro f0 f1'
          'f2 f3 f4';
        gap: var(--gap-md);
      }

      ${StyledGlassTile}:last-of-type {
        ${StyledImage} {
          width: 65%;

          ${breakpoint.md} {
            width: 75%;
          }

          ${breakpoint.xl} {
            width: 65%;
          }

          img {
            object-position: top right;
          }
        }
      }
    }
  }

  /* Careers */
  &[data-page='careers'] {
    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3';
      gap: var(--gap-xl);

      ${breakpoint.md} {
        gap: 112px;
      }

      ${StyledIntro} {
        padding: var(--padding-base);

        ${breakpoint.md} {
          max-width: 440px;
          padding: unset;
          margin-bottom: var(--gap-xxxxxl);
        }

        ${breakpoint.lg} {
          max-width: 563px;
        }

        ${breakpoint.xl} {
          padding: 0;
        }
      }
    }
  }

  /* Home */
  &[data-page='home'] {
    ${StyledIntro} {
      ${breakpoint.md} {
        position: sticky;
        top: 40%;
      }
    }

    ${StyledList} {
      grid-template-areas:
        'intro'
        'f0'
        'f1'
        'f2'
        'f3'
        'f4';
      gap: var(--gap-sm);
      max-width: 480px;
      margin: 0 auto;

      ${breakpoint.md} {
        grid-template-areas:
          'intro f0'
          'intro f1'
          'intro f2'
          'intro f3'
          'intro f4';
        grid-template-columns: 0.8fr 1.2fr;
        align-items: start;
        max-width: unset;
      }

      ${breakpoint.lg} {
        gap: var(--gap-lg);
      }

      ${StyledGlassTile} {
        &:last-of-type {
          ${StyledImage} {
            width: 65%;

            ${breakpoint.md} {
              width: 75%;
            }

            ${breakpoint.xl} {
              width: 65%;
            }

            img {
              object-position: top right;
            }
          }
        }
      }
    }
  }
`;
