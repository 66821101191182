import React, { useMemo } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Button from 'components/atoms/Button';
import Typography from 'components/atoms/Typography';
import Feature from 'components/molecules/Feature';

import { descriptionVariant, homeTextVariant, titleVariant } from './ServicesSection.constants';
import {
  StyledCircleImage,
  StyledHomeTextVariant,
  StyledIntro,
  StyledList,
  StyledWrapper,
} from './ServicesSection.styles';

import { ServicesSectionProps } from './models.d';

const ServicesSection = ({
  whichPage,
  title,
  description,
  features,
  button,
  circleLogo,
}: ServicesSectionProps) => {
  const isHome = whichPage === 'home';
  const renderDescription = () => {
    if (!description) return null;

    return isHome ? (
      <StyledHomeTextVariant {...homeTextVariant} id="services">
        {renderRichText(description)}
      </StyledHomeTextVariant>
    ) : (
      <Typography {...descriptionVariant}>{renderRichText(description)}</Typography>
    );
  };

  const featuresList = useMemo(
    () =>
      features?.map((feature, index) => (
        <Feature key={feature.title} {...{ ...feature, whichPage }} order={index} />
      )),
    [features, whichPage]
  );

  return features?.length ? (
    <StyledWrapper aria-labelledby="services" data-page={whichPage}>
      {circleLogo ? <StyledCircleImage image={circleLogo.image} alt={circleLogo.alt} /> : null}
      <StyledList data-page={whichPage}>
        {title || description || button ? (
          <StyledIntro>
            {title ? (
              <Typography id="services" {...titleVariant}>
                {title}
              </Typography>
            ) : null}
            {description ? renderDescription() : null}
            {button ? <Button {...button} /> : null}
          </StyledIntro>
        ) : null}
        {featuresList}
      </StyledList>
    </StyledWrapper>
  ) : null;
};

export default ServicesSection;
